<template>
  <div class="search_wrapper">
    <span class="shaixuan">筛选</span>
    <div class="search_wrappe_con">
      <a-range-picker
        class="search_item"
        format="YYYY-MM-DD"
        :placeholder="['Start', 'End']"
        @change="ChangeDate"
        :value="rangePickerDate"
        :allowClear="false"
        :ranges="{
          今日: [initDate(0, 0).start, initDate(0, 0).end],
          昨日: [initDate(1, 1).start, initDate(1, 1).end],
          最近一周: [initDate(6, 0).start, initDate(6, 0).end],
          最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
        }"
        :disabledDate="disabledDate"
      >
      </a-range-picker>
      <m-select-more
        class="search_item"
        v-model="searchquery.appIdList"
        :allData="appList"
        :searchById="true"
        :hasIcon="true"
        :showId="true"
        width="450px"
        label="应用"
        @change="changeAppIds"
      />
      <m-select-more
        class="search_item"
        v-model="searchquery.placeIdList"
        :allData="placeList"
        :searchById="true"
        :hasIcon="true"
        :showId="true"
        width="450px"
        label="广告位"
        @change="changePlaceIds"
      />
      <m-select-more
        class="search_item"
        v-model="searchquery.sourceIdList"
        :allData="sourceList"
        :searchById="true"
        :hasIcon="false"
        :showId="true"
        label="广告源"
        :showSelectedPart="true"
        width="450px"
        :canSelectAll="true"
        @change="handleAutoSearch"
      />
      <m-select-more
        class="search_item"
        v-model="searchquery.platIdList"
        :allData="platList"
        :searchById="false"
        :hasIcon="false"
        :showId="false"
        label="广告平台"
        :showSelectedPart="true"
        width="450px"
        :canSelectAll="true"
        @change="handleAutoSearch"
      />
      <div class="search_item input">
        <a-input
          placeholder="请输入广告平台广告位ID"
          allowClear
          v-model.trim="searchquery.platPlaceId"
          @input="changeInputValue"
        />
      </div>
      <div class="search_item input">
        <a-input placeholder="请输入错误码" allowClear v-model.trim="searchquery.code" @input="changeInputValue" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mixDate from '@/mixins/initDate'
import mixGetList from '@/mixins/getList'
import { getDuration } from '@/utils/duration'
export default {
  mixins: [mixDate, mixGetList],
  data () {
    return {
      searchquery: {
        dateEnd: '',
        dateStart: '',
        appIdList: [],
        placeIdList: [],
        sourceIdList: [],
        platIdList: [],
        platPlaceId: '',
        code: ''
      },
      timer: null
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title
    })
  },
  async created () {
    // 初始化时间
    const { endDate, startDate, end, start } = this.initDate(7, 1)
    this.rangePickerDate = [start, end]
    this.searchquery.dateEnd = endDate
    this.searchquery.dateStart = startDate
    await this.getAppList()
    this.appList = this.appList.filter((item) => {
      return item.accessType === 0
    })
    this.changeAppIds(this.appList.map((item) => item.id))
    // this.getPlaceList()
    // this.getSourceList()
    await this.getPlatList()
    this.platList = this.platList.filter((item) => {
      return item.accessType === '0'
    })
    // 进入时搜索
    this.$emit('handleAutoSearch', this.searchquery)
  },
  methods: {
    changeInputValue () {
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.handleAutoSearch()
      }, 1000)
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    ChangeDate (date, dateString) {
      this.searchquery = {
        ...this.searchquery,
        dateEnd: dateString[1],
        dateStart: dateString[0]
      }
      this.rangePickerDate = date
      this.handleAutoSearch()
    },
    // 应用改变查广告位
    async changeAppIds (ids) {
      if (ids.length === 0) {
        // app无值 通过全部appList查place列表
        await this.getPlaceListByAppIds(this.appList.map((item) => item.id))
      } else {
        // app选了 通过选了的app查place列表
        await this.getPlaceListByAppIds(ids)
      }
      this.changePlaceIds(this.searchquery.placeIdList)
    },
    // 广告位改变查流量分组
    async changePlaceIds (ids) {
      if (ids.length === 0) {
        await this.getSourceListByAdplace(this.placeList.map((item) => item.id))
      } else {
        this.getSourceListByAdplace(ids)
      }
      this.handleAutoSearch()
    },
    handleAutoSearch () {
      this.$emit('handleAutoSearch', this.searchquery)
    }
  }
}
</script>

<style lang="less" scoped>
.search_wrapper {
  border-radius: 5px;
  padding: 20px 0 10px 0;
  display: flex;
  align-items: center;
  .shaixuan {
    width: 70px;
    color: #333;
    font-weight: 500;
  }
  .search_wrappe_con {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 10px;
  }
  .search_item {
    padding: 0 5px;
    margin: 5px 0;
    width: 240px;
    display: inline-block;
    &.input {
      padding: 0 5px;
    }
  }
}
</style>
